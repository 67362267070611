import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import FancyTitle from '../components/fancy-title';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';
import ImagePane from '../components/image-pane';

function UseCasesPage({ data, location }) {
  const [page, setPage] = React.useState(1);
  const [init, setInit] = React.useState(false);

  const pageLength = 9;

  let cases = data.allUseCasesYaml.edges;
  const selectedCase = cases[0].node;
  cases = cases.filter((e) => e.slug !== selectedCase.slug);
  const pageCount = Math.ceil(cases.length / pageLength);

  const selectedImage = getImage(selectedCase.image);

  const changePage = (event, toPage) => {
    event.preventDefault();
    setPage(toPage);
    window.history.pushState(`page${toPage}`, 'Use Cases', `?page=${toPage}`);
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const _page = params.get('page');
    if (!_page) {
      setPage(1);
      return;
    }

    if (_page !== page) {
      setPage(_page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  React.useEffect(() => {
    if (!init) return;
    document.getElementById('use-cases-list').scrollIntoView({ behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  React.useEffect(() => {
    setInit(true);
  }, []);

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'Use Cases', fullWidth: true });
        return (
          <div className="px-4 lg:px-16 flex flex-col py-20 lg:py-36 lg:pb-40">
            <Seo title="Shopify Use Cases" description="Unsere Use Cases zu Shopify & Shopify Plus ✓ Migration von anderen Shopsystemen ✓ Anschluss von PIM Systemen & mehr" />

            <FancyTitle
              text="Unsere Shopify Use Cases"
              className="my-0"
              tag="h1"
              textAlign="text-center lg:text-center"
              marginBottom="mb-20"
            />
            <div className="mb-10 lg:mb-24 text-lg">
              Als langjähriger Shopify Partner bieten wir unseren Kunden eine Vielzahl
              von Lösungen, die zu einem echten Mehrwert für sie werden. Entdecken Sie
              viele verschiedene innovative Use Cases rund um die Themen Migration zu
              Shopify oder die Anbindung von Schnittstellen. Erfahren Sie, wie wir Sie
              als Shopify Agentur Unternehmen dabei unterstützen, durch maßgeschneiderte
              Lösungen und sinnvolle Integrationen erfolgreich online zu verkaufen.
            </div>

            <div className="gap-x-6">
              <ImagePane
                image={selectedImage && (
                  <Link to={`/use-cases/${selectedCase.slug}`} className="w-full">
                    <GatsbyImage
                      image={selectedImage}
                      alt={selectedCase.short_description}
                      className="w-full"
                    />
                  </Link>
                )}
                title={(
                  <FancyTitle
                    text={selectedCase.title}
                    tag="h2"
                    className="mb-8 xl:mb-12"
                  />
                )}
                desktopImageWidth="lg:w-3/5 2xl:w-2/5"
                desktopTitleWidth="lg:w-2/5 2xl:w-3/5"
                desktopTitleVerticalPosition="center"
                content={(
                  <>
                    <p className="leading-6 py-8 xl:py-0" dangerouslySetInnerHTML={{ __html: selectedCase.description }} />
                    <Link to={`/use-cases/${selectedCase.slug}`} className="button-primary  mt-4 inline-block">MEHR</Link>
                  </>
                )}
              />
            </div>
            <div className="mt-[60px] lg:mt-20 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2 md:gap-6" id="use-cases-list">

              {cases.slice(pageLength * (page - 1) + 1, pageLength * page).map((caseEdge) => {
                const _case = caseEdge.node;
                const image = getImage(_case.image);
                return (
                  <Link
                    key={_case.slug}
                    to={`/use-cases/${_case.slug}`}
                    className="mx-auto aspect-[24/10] w-full relative group"
                  >
                    {image
                      && (
                      <GatsbyImage
                        image={image}
                        alt={_case.short_description}
                        className="w-full h-full absolute top-0 left-0"
                      />
                      )}
                    <div className="bg-latori-green-dark opacity-90 w-full h-full absolute bottom-0 left-0 transition-[height] group-hover:h-[0%]" />
                    <div className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-start px-4 group-hover:opacity-0 transition-opacity text-white">
                      <h2 className="text-2xl">{_case.title}</h2>
                      <p dangerouslySetInnerHTML={{ __html: _case.short_description }} className="hidden md:block md:line-clamp-3" />
                    </div>
                  </Link>
                );
              })}
            </div>
            {pageCount > 1 && (
              <div className="px-6 md:px-8 mt-[100px] mb-[100px] lg:mb-60 md:text-center gap-4">
                {
                  page > 1 && (
                    <Link
                      to={`?page=${page - 1}`}
                      onClick={(e) => changePage(e, page - 1)}
                      className="inline-block"
                    >
                      &lt; PREV
                    </Link>
                  )
                }
                {Array(pageCount).fill(0).map((_, i) => {
                  const _page = i + 1;
                  return (
                    <Link
                      key={`page-l-${_page}`}
                      to={`?page=${_page}`}
                      onClick={(e) => changePage(e, _page)}
                      className={`w-[42px] h-[42px] rounded-full inline-flex justify-center items-center ${_page === page ? 'bg-latori-green-dark text-white' : ''}`}
                    >
                      {_page}
                    </Link>
                  );
                })}
                {
                  pageCount > page && (
                    <Link
                      to={`?page=${page + 1}`}
                      onClick={(e) => changePage(e, page + 1)}
                      className="inline-block"
                    >
                      NEXT &gt;
                    </Link>
                  )
                }
              </div>
            )}
          </div>
        );
      }}
    </LayoutConsumer>
  );
}

export default UseCasesPage;

export const useCasesQuery = graphql`
  query {
    allUseCasesYaml {
      edges {
        node {
          slug
          title
          short_description
          description
          image {
            childImageSharp {
              gatsbyImageData(
                width: 720,
                placeholder: BLURRED,
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          sample {
            title
            description
            background_color
            image {
              name
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 720,
                  placeholder: BLURRED,
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
